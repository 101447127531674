import './css/main.css'
import './css/vendor.css'
import boardWebp from './img/board.webp'
import board2Webp from './img/board-2.webp'
import board4Webp from './img/board-4.webp'
import avocadoWebp from './img/avocado.webp'
import safeWebp from './img/safe.webp'
import sprite from './img/sprite.svg'
import {
  chainParams,
  contractABI,
  contractAddress,
  contractPartnershipABI,
  contractPartnershipAddress,
  periods,
  stakingTokenABI,
  stakingTokenAddress,
} from './contractInfo'
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { checkMetamaskInstallation } from './web3Utils'
import { PropagateLoader, RingLoader } from 'react-spinners'
import { Popover, Snackbar } from '@mui/material'
import moment from 'moment'
import QRCode from 'react-qr-code'
import ethProvider from 'eth-provider'
import { Trans, useTranslation } from 'react-i18next'

function App() {
  const [loading, setLoading] = useState(false)
  const [loadingContract, setLoadingContract] = useState(true)
  const [alertShow, setAlertShow] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [langPopper, setLangPopper] = useState(null)
  const [stakingTab, setStakingTab] = useState(0)
  const [activeSpoller, setActiveSpoller] = useState(0)
  const [activeSpollerTab, setActiveSpollerTab] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [modalUnstakeShow, setModalUnstakeShow] = useState(false)
  const [modalDisconnectShow, setModalDisconnectShow] = useState(false)
  const [stakingDurationIndex, setStakingDurationIndex] = useState(0)
  const [unstakeIndex, setUnstakeIndex] = useState(null)
  const [account, setAccount] = useState('')
  const [provider, setProvider] = useState(null)
  const [signer, setSigner] = useState(null)
  const [contract, setContract] = useState(null)
  const [partnershipContract, setPartnershipContract] = useState(null)
  const [currentChainId, setCurrentChainId] = useState(window.ethereum?.chainId ?? chainParams.chainId)
  const [refUser, setRefUser] = useState(new URLSearchParams(window.location.search).get('ref') ?? null)

  const [TVLs, setTVLs] = useState([])
  const [rates, setRates] = useState([])
  const [refBonusStages, setRefBonusStages] = useState([])
  const [minStakeAmount, setMinStakeAmount] = useState(0)

  const [partnershipPools, setPartnershipPools] = useState([])
  const [partnershipUser, setPartnershipUser] = useState([])

  const [totalReferrals, setTotalReferrals] = useState(0)
  const [myStakes, setMyStakes] = useState([])

  const [t, i18n] = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  useEffect(() => {
    if (refUser) {
      localStorage.setItem('refUser', refUser)
      window.history.pushState(null, '', window.location.origin + window.location.pathname)
    }
  }, [])

  useEffect(() => {
    const getContractInfo = async () => {
      try {
        const providerOne = ethProvider(chainParams.rpcUrls)
        const providerTemp = new ethers.BrowserProvider(providerOne)
        const contractTemp = new ethers.Contract(contractAddress, contractABI, providerTemp)
        const contractPartnershipTemp = new ethers.Contract(
          contractPartnershipAddress,
          contractPartnershipABI,
          providerTemp
        )
        const refBonusStages = await Promise.all(Array.from({ length: 4 }, (_, i) => contractTemp.refBonusStages(i)))
        const aprListProxy = await Promise.all(periods.map((period) => contractTemp.baseAprRates(period)))
        const tvlListProxy = await Promise.all(periods.map((period) => contractTemp.getTVL(period)))
        const minStake = await contractTemp.minStakeAmount()
        const partnerPoolLength = Number(await contractPartnershipTemp.poolLength())
        const partnerPoolListProxy = await Promise.all(
          Array.from({ length: partnerPoolLength }, (_, i) => contractPartnershipTemp.poolInfo(i))
        )
        const partnerPoolList = JSON.parse(
          JSON.stringify(partnerPoolListProxy, (key, value) => (typeof value === 'bigint' ? value.toString() : value))
        ).map((item, i) => ({
          rewardPerBlockAmount: item[0],
          rewardTokenAddress: item[1],
          rewardsVaultAddress: item[2],
          depositedTokenAddress: item[3],
          totalDepositedAmount: item[4],
          lastRewardBlock: item[5],
          harvestAvailableBlock: item[6],
          harvestPeriod: item[7],
          accRewardsPerShare: item[8],
          getRewardTokenSymbol: async () => {
            const tokenCon = new ethers.Contract(item[1], stakingTokenABI, providerTemp)
            return await tokenCon.symbol()
          },
          getDepositedTokenSymbol: async () => {
            const tokenCon = new ethers.Contract(item[3], stakingTokenABI, providerTemp)
            return await tokenCon.symbol()
          },
          getLastRewardBlockTimestamp: async () => {
            const block = await providerTemp.getBlock(Number(item[5]))
            return block?.timestamp
          },
        }))
        await Promise.all(
          partnerPoolList.map(async (token) => {
            token.rewardTokenSymbol = await token.getRewardTokenSymbol()
            token.depositedTokenSymbol = await token.getDepositedTokenSymbol()
            token.lastRewardBlockTimestamp = await token.getLastRewardBlockTimestamp()
          })
        )
        setPartnershipPools(partnerPoolList)
        setTVLs(tvlListProxy)
        setRates(aprListProxy)
        setRefBonusStages(refBonusStages)
        setMinStakeAmount(minStake)
      } catch (error) {
        console.log(error)
      }
    }
    setLoadingContract(true)
    getContractInfo().finally(() => setLoadingContract(false))
  }, [])

  const getAccountInfo = async () => {
    const numberOfStakes = Number(await contract.getNumUserStaked(account))
    const stakeListProxy = await Promise.all(
      Array.from({ length: numberOfStakes }, (_, i) => contract.userStakes(account, i))
    )
    // for (let i = 0; true; i++) {
    //   try {
    //     const stake = await contract.userStakes(account, i)
    //     stakeListProxy.push(stake)
    //   } catch (error) {
    //     // Stop further calls if there's an error
    //     break
    //   }
    // }
    const rewardListProxy = await Promise.all(
      Array.from({ length: stakeListProxy.length }, (_, i) => contract.calculateReward(account, i))
    )
    const totalReferralsProxy = await contract.totalReferrals(account)
    setTotalReferrals(Number(totalReferralsProxy))
    const stakeList = JSON.parse(
      JSON.stringify(stakeListProxy, (key, value) => (typeof value === 'bigint' ? value.toString() : value))
    ).map((item, i) => ({
      amount: item[0],
      stakedTime: item[1],
      period: item[2],
      reward: rewardListProxy[i].toString(),
    }))

    const partnershipLength = Number(await partnershipContract.poolLength())
    const partnershipUserProxy = await Promise.all(
      Array.from({ length: partnershipLength }, (_, i) => partnershipContract.userInfo(i, account))
    )
    const partnershipUser = JSON.parse(
      JSON.stringify(partnershipUserProxy, (key, value) => (typeof value === 'bigint' ? value.toString() : value))
    ).map((item, i) => ({
      depositedAmount: item[0],
      lastHarvestedBlock: item[1],
      pendingAmount: item[2],
      rewardDebt: item[3],
    }))

    setMyStakes(stakeList)
    setPartnershipUser(partnershipUser)
  }

  const stakeHandler = async (stakeValue) => {
    setLoading(true)
    if (contract) {
      const stakeAmount = ethers.parseEther(stakeValue.toString())
      const tokenContract = new ethers.Contract(stakingTokenAddress, stakingTokenABI, signer)
      try {
        const allowance = await tokenContract.allowance(signer.getAddress(), contractAddress)
        if (allowance < stakeAmount) {
          const approvalTx = await tokenContract.approve(contractAddress, stakeAmount)
          await approvalTx.wait() // Wait for the approval transaction to be mined
        } else {
          console.log('Tokens already approved')
        }
        const referralRelationships = await contract.referralRelationships(account)
        const tx = await contract.stake(
          stakeAmount,
          periods[stakingDurationIndex],
          referralRelationships === ethers.ZeroAddress
            ? localStorage.getItem('refUser') &&
              localStorage.getItem('refUser') !== account &&
              localStorage.getItem('refUser') !== referralRelationships
              ? localStorage.getItem('refUser')
              : ethers.ZeroAddress
            : ethers.ZeroAddress
        )
        await tx.wait()
        setAlertText(stakeValue + ' Tokens were staked successfully')
        setAlertShow(true)
        setModalShow(false)
        getAccountInfo()
      } catch (error) {
        console.log(error)
        setAlertText(error.reason)
        setAlertShow(true)
      }
    }
    setLoading(false)
  }
  const unstakeHandler = async () => {
    setLoading(true)
    if (contract) {
      try {
        // const contractReader = new ethers.Contract(contractAddress, contractABI, provider)
        const tx = await contract.unstake(unstakeIndex)
        await tx.wait()
        setAlertText(
          (
            Number(ethers.formatEther(myStakes[unstakeIndex].amount)) +
            Number(ethers.formatEther(myStakes[unstakeIndex].reward))
          ).toString() + ' AVO were unstaked successfully!'
        )
        setAlertShow(true)
        setModalUnstakeShow(false)
        setUnstakeIndex(null)
        getAccountInfo()
      } catch (error) {
        console.log(error)
        setAlertText(error.reason ?? 'Staking period not ended')
        setAlertShow(true)
      }
    }
    setLoading(false)
    setModalUnstakeShow(false)
  }
  const partnerStakeHandler = async (pid, stakeValue) => {
    setLoading(true)
    if (partnershipContract) {
      const stakeAmount = ethers.parseEther(stakeValue.toString())
      const tokenContract = new ethers.Contract(stakingTokenAddress, stakingTokenABI, signer)
      try {
        const allowance = await tokenContract.allowance(signer.getAddress(), contractAddress)
        if (allowance < stakeAmount) {
          const approvalTx = await tokenContract.approve(contractPartnershipAddress, stakeAmount)
          await approvalTx.wait() // Wait for the approval transaction to be mined
        } else {
          console.log('Tokens already approved')
        }
        const tx = await partnershipContract.deposit(pid, stakeAmount)
        await tx.wait()
        setAlertText(stakeValue + ' Tokens were staked successfully')
        setAlertShow(true)
        getAccountInfo()
      } catch (error) {
        console.log(error)
        setAlertText(error.reason)
        setAlertShow(true)
      }
    }
    setLoading(false)
  }

  const partnerUnstakeHandler = async (pid, unstakeValue) => {
    setLoading(true)
    if (partnershipContract) {
      try {
        const unstakeAmount = ethers.parseEther(unstakeValue.toString())
        // const contractReader = new ethers.Contract(contractAddress, contractABI, provider)
        const tx = await partnershipContract.withdraw(pid, unstakeAmount)
        await tx.wait()
        setAlertText(unstakeValue.toString() + ' AVO were withdrawn successfully!')
        setAlertShow(true)
        setModalUnstakeShow(false)
        getAccountInfo()
      } catch (error) {
        console.log(error)
        setAlertText(error.reason ?? 'Staking period not ended')
        setAlertShow(true)
      }
    }
    setLoading(false)
    setModalUnstakeShow(false)
  }

  const connectWallet = async () => {
    checkMetamaskInstallation(setProvider, setSigner, setContract, setPartnershipContract, setAccount)
  }

  const disconnectWallet = async () => {
    localStorage.removeItem('metamaskConnected')
    setAccount(null)
  }

  const convertSecondsToDuration = (seconds) => {
    const duration = moment.duration(seconds, 'seconds')

    // Calculate total months and years from the duration
    const totalMinutes = Math.round(duration.asMinutes())
    const totalHours = Math.round(duration.asHours())
    const totalMonths = Math.round(duration.asMonths())
    const totalYears = Math.round(duration.asYears())

    // Return human-readable duration based on thresholds
    if (totalYears >= 1) {
      return `${totalYears} year${totalYears > 1 ? 's' : ''}`
    } else if (totalMonths >= 1) {
      return `${totalMonths} month${totalMonths > 1 ? 's' : ''}`
    } else if (totalHours >= 1) {
      return `${totalHours} hour${totalHours > 1 ? 's' : ''}`
    } else if (totalMinutes >= 1) {
      return `${totalMinutes} minute${totalMinutes > 1 ? 's' : ''}`
    } else {
      return 'less than a month'
    }
  }

  const calculateTimeLeft = (stakedTime, duration) => {
    const releaseTime = moment.unix(stakedTime + duration)
    const now = moment()
    const diff = moment.duration(releaseTime.diff(now))
    const days = Math.floor(diff.asDays())
    const hours = diff.hours()
    const minutes = diff.minutes()

    return `${days < 0 ? 0 : days}D ${hours < 0 ? 0 : hours}H ${minutes < 0 ? 0 : minutes}M`
  }

  useEffect(() => {
    // Function to handle network changes
    const handleNetworkChange = (chainId) => {
      console.log(`Network changed to: ${chainId}`)
      // Reload the page when the network changes
      setCurrentChainId(chainId)
    }

    // Check if MetaMask is installed
    if (window.ethereum) {
      // Handle network changes
      window.ethereum.on('chainChanged', handleNetworkChange)

      // Clean up the event listener on component unmount
      return () => {
        window.ethereum.removeListener('chainChanged', handleNetworkChange)
      }
    } else {
      setAlertText('MetaMask is not installed.')
      setAlertShow(true)
    }
  }, [])

  useEffect(() => {
    async function checkMetaMaskAccess() {
      if (window.ethereum && localStorage.getItem('metamaskConnected') === 'true') {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' })

          if (accounts.length > 0) {
            await checkMetamaskInstallation(setProvider, setSigner, setContract, setPartnershipContract, setAccount)
          } else {
            console.log('No accounts connected')
            return null
          }
        } catch (error) {
          console.error('Error checking MetaMask accounts:', error)
        }
      } else {
        setAlertText(window.ethereum ? 'Connect your wallet' : 'MetaMask not detected')
        setAlertShow(true)
      }
    }
    checkMetaMaskAccess()
  }, [])

  useEffect(() => {
    if (contract && partnershipContract && currentChainId === chainParams.chainId && account) {
      getAccountInfo()
    }
  }, [contract, partnershipContract, currentChainId, account])

  useEffect(() => {
    if (modalShow || modalUnstakeShow) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [modalShow, modalUnstakeShow])

  return (
    <div className="App">
      <div className="wrapper">
        <header className="header">
          <div className="header__container">
            <a href="/" className="header__logo logo">
              <img className="logo__icon" src={require('./img/logo-icon.svg').default} alt="logo" />
              <div className="logo__name">
                <img className="logo__name-icon" src={require('./img/logo-name.svg').default} alt="logo" />
                <span className="logo__name-text">finance</span>
              </div>
            </a>
            <div className="header__actions">
              <button onClick={(e) => setLangPopper(e.currentTarget)} className="header__lang">
                <span className="header__lang-text">{i18n.language}</span>
                <svg className="header__lang-arrow">
                  <use xlinkHref={`${sprite}#arrow`}></use>
                </svg>
              </button>

              <Popover
                open={Boolean(langPopper)}
                anchorEl={langPopper}
                disableScrollLock
                onClose={() => setLangPopper(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className="lang_menu">
                  <button
                    className="lang_item"
                    onClick={() => {
                      i18n.changeLanguage('en')
                      setLangPopper(null)
                    }}
                  >
                    English
                  </button>
                  <button
                    className="lang_item"
                    onClick={() => {
                      i18n.changeLanguage('ru')
                      setLangPopper(null)
                    }}
                  >
                    Русский
                  </button>
                </div>
              </Popover>

              {account ? (
                <>
                  {currentChainId !== chainParams.chainId ? (
                    <button
                      onClick={() =>
                        checkMetamaskInstallation(
                          setProvider,
                          setSigner,
                          setContract,
                          setPartnershipContract,
                          setAccount
                        )
                      }
                    >
                      ⚠️ switch network!
                    </button>
                  ) : null}
                  <p className="header__button button" onClick={() => setModalDisconnectShow(true)}>
                    {account.slice(0, 6) + '...' + account.slice(account.length - 6, account.length)}
                  </p>
                </>
              ) : (
                <button onClick={() => connectWallet()} className="header__button button">
                  <Trans i18nKey="CONNECT WALLET">Connect Wallet</Trans>
                </button>
              )}
            </div>
          </div>
        </header>

        <main className="page">
          <div className="page__container">
            <div className="staking">
              <div className={`staking__content ${stakingTab === 1 ? 'staking__content--partnership' : ''}`}>
                <div className={`staking__background ${stakingTab === 1 ? 'staking__background--partnership' : ''}`}>
                  <picture>
                    <source media="(min-width: 769px)" srcSet={boardWebp} type="image/webp" />
                    <source
                      media="(max-width: 768px)"
                      srcSet={stakingTab === 0 ? board2Webp : board4Webp}
                      type="image/webp"
                    />
                    <img src={require('./img/board.png')} className="image" alt="" />
                  </picture>
                </div>
                <nav className="staking__menu menu">
                  <ul className="menu__list">
                    <li className="menu__item">
                      <div
                        onClick={() => setStakingTab(0)}
                        className={`menu__link ${stakingTab === 0 ? 'active' : ''}`}
                      >
                        Staking
                      </div>
                    </li>
                    <li className="menu__item">
                      <div
                        onClick={() => setStakingTab(1)}
                        className={`menu__link ${stakingTab === 1 ? 'active' : ''}`}
                      >
                        Partnership <span> Staking</span>
                      </div>
                    </li>
                  </ul>
                </nav>
                {stakingTab === 0 ? (
                  <div className="staking__body">
                    <div className="staking__info">
                      <p className="staking__info-text">
                        <Trans i18nKey="staking_title">
                          Your <span className="text-yellow">APR depends</span> on the number of users you invite, 1st
                          and 2nd level referrals are taken into account:
                        </Trans>
                      </p>
                      {!loadingContract ? (
                        <>
                          <ul className="staking__info-list">
                            <li className="staking__info-item">
                              <Trans i18nKey="staking_item1" values={{ refCount: Number(refBonusStages[0][0]) }}>
                                <span className="text-yellow">APR x 1</span>
                                Number of referrals up to {'{{refCount}}'} users
                              </Trans>
                            </li>
                            <li className="staking__info-item">
                              <Trans
                                i18nKey="staking_item2"
                                values={{
                                  refCount1: Number(refBonusStages[0][0]) + 1,
                                  refCount2: Number(refBonusStages[1][0]),
                                  apr: Number(refBonusStages[0][1]) / 100,
                                }}
                              >
                                <span className="text-yellow">APR x {'{{apr}}'}</span>
                                Number of referrals from {'{{refCount1}}'} to
                                {'{{refCount2}}'} users
                              </Trans>
                            </li>
                            <li className="staking__info-item">
                              <Trans
                                i18nKey="staking_item2"
                                values={{
                                  refCount1: Number(refBonusStages[1][0]) + 1,
                                  refCount2: Number(refBonusStages[2][0]),
                                  apr: Number(refBonusStages[1][1]) / 100,
                                }}
                              >
                                <span className="text-yellow">APR x {'{{apr}}'}</span>
                                Number of referrals from {'{{refCount1}}'} to
                                {'{{refCount2}}'} users
                              </Trans>
                            </li>
                            <li className="staking__info-item">
                              <Trans
                                i18nKey="staking_item2"
                                values={{
                                  refCount1: Number(refBonusStages[2][0]) + 1,
                                  refCount2: Number(refBonusStages[3][0]),
                                  apr: Number(refBonusStages[2][1]) / 100,
                                }}
                              >
                                <span className="text-yellow">APR x {'{{apr}}'}</span>
                                Number of referrals from {'{{refCount1}}'} to
                                {'{{refCount2}}'} users
                              </Trans>
                            </li>
                            <li className="staking__info-item">
                              <Trans
                                i18nKey="staking_item3"
                                values={{
                                  refCount1: Number(refBonusStages[3][0]) + 1,
                                  apr: Number(refBonusStages[3][1]) / 100,
                                }}
                              >
                                <span className="text-yellow">APR x {'{{apr}}'}</span>
                                Number of referrals from {'{{refCount1}}'} users
                              </Trans>
                            </li>
                          </ul>
                          {account && (
                            <div className="staking__info-referrals referrals-info">
                              <h2 className="referrals-info__title">
                                <Trans i18nKey="yourReferrals">Your referrals:</Trans>
                              </h2>
                              <div className="referrals-info__items">
                                <div
                                  className="referrals-info__item"
                                  style={{
                                    flex: 1,
                                    textAlign: 'right',
                                  }}
                                >
                                  {/* <label className="referrals-info__label">1st level</label> */}
                                  <div className="referrals-info__amount">{totalReferrals}</div>
                                </div>
                                {/* <div className="referrals-info__item">
                                  <label className="referrals-info__label">2nd level</label>
                                  <div className="referrals-info__amount">{referralInfo.sndLevelNumber}</div>
                                </div> */}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                          <RingLoader color="#e3fb4c" />
                        </div>
                      )}
                    </div>
                    <div className="staking__actions">
                      <div className="staking__frame">
                        {account ? (
                          <div className="staking__actions-content">
                            <div className="staking__actions-qr">
                              <QRCode
                                bgColor="transparent"
                                className="staking__actions-qr-code"
                                size="auto"
                                value={window.location.origin + '/?ref=' + account}
                                alt="qr"
                              />
                              <p className="staking__actions-qr-text">
                                <Trans i18nKey="scanQr">
                                  Scan the QR code to receive your referral link or copy the text below
                                </Trans>
                              </p>
                            </div>
                            <div className="staking__invite">
                              <label className="staking__invite-label">
                                <Trans i18nKey="inviteLink">Invite Link</Trans>
                              </label>
                              <div className="staking__invite-link">
                                <span className="staking__invite-address">
                                  {(window.location.origin + '/?ref=' + account).slice(
                                    0,
                                    window.innerWidth > 940 ? 18 : 10
                                  ) +
                                    '...' +
                                    (window.location.origin + '/?ref=' + account).slice(-5)}
                                </span>
                                <button
                                  onClick={() => {
                                    navigator.clipboard.writeText(window.location.origin + '/?ref=' + account)
                                    setAlertText('Link copied to the clipboard!')
                                    setAlertShow(true)
                                  }}
                                  className="staking__invite-btn-copy"
                                >
                                  <svg className="staking__invite-btn-icon">
                                    <use xlinkHref={`${sprite}#copy`}></use>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="staking__actions-content">
                            <img className="staking__actions-image" src={require('./img/safe.png')} alt="safe-icon" />
                            <p className="staking__actions-text">
                              <Trans i18nKey="connectForBenefits">
                                Connect your wallet and stake your AVO for more benefits
                              </Trans>
                            </p>
                            <button onClick={connectWallet} className="staking__actions-btn button">
                              <Trans i18nKey="CONNECT WALLET">Connect Wallet</Trans>
                            </button>
                          </div>
                        )}
                        <div className="staking__frame-corner staking__frame-corner--1">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--2">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--3">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--4">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="staking__body staking__body--partnership">
                    <div className="staking__info staking-partnership__info">
                      <h2 className="staking-partnership__info-title">
                        <span className="staking-partnership__info-title-avo">
                          A<span>VO</span>
                        </span>{' '}
                        finance
                      </h2>
                      <div className="staking-partnership__content">
                        <p className="staking-partnership__text">
                          <Trans i18nKey="partnershipTitle">
                            <span className="text-yellow">Stake your AVO </span>to earn rewards in partner project
                            tokens.
                          </Trans>
                        </p>
                        <div className="staking-partnership__price">
                          <div className="staking-partnership__price-item">
                            <span className="staking-partnership__price-label">TVL:</span>
                            <div className="staking-partnership__price-amount">
                              <span className="staking-partnership__price-value">$123 768.35</span>
                              <span className="staking-partnership__price-change change-up">+9.23%</span>
                            </div>
                          </div>
                          {/* <div className="staking-partnership__price-item">
                            <span className="staking-partnership__price-label"> AVO price:</span>
                            <div className="staking-partnership__price-amount">
                              <span className="staking-partnership__price-value">$0.00253</span>
                              <span className="staking-partnership__price-change change-up">+7.85%</span>
                            </div>
                          </div> */}
                        </div>
                        <a
                          target="_blank"
                          href="https://pancakeswap.finance/swap?outputCurrency=0x14EE333538B4621a600F011E508D783eA200d60e&inputCurrency=0x55d398326f99059fF775485246999027B3197955"
                          className="button staking-partnership__button"
                          rel="noreferrer"
                        >
                          <Trans>buy avo</Trans>
                        </a>
                      </div>
                    </div>
                    <div className="staking__actions staking__actions--partnership">
                      <div className="staking__frame">
                        <div className="staking__actions-content">
                          <div className="staking__actions-image-avo">
                            <picture>
                              <source srcSet={avocadoWebp} type="image/webp" />
                              <img src={require('./img/avocado.png')} className="image" alt="" />
                            </picture>
                          </div>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--1">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--2">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--3">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--4">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {stakingTab === 0 ? (
                <div className="staking__pools">
                  <div className="pools">
                    <h2 className="pools__title">
                      <span className="pools__title-avo">
                        a<span>vo</span>
                      </span>{' '}
                      Staking pools
                    </h2>
                    {!loadingContract && rates.length !== 0 ? (
                      <div className="pools__list">
                        {rates.map((rate, i) => (
                          <button
                            key={i}
                            className="pools__item item-pools card"
                            onClick={() => {
                              setModalShow(true)
                              setStakingDurationIndex(i)
                            }}
                          >
                            <div className="item-pools__label">APR</div>
                            <div className="item-pools__profit">
                              <Trans>from</Trans>
                              <span className="item-pools__profit-from text-yellow"> {Number(rates[i]) / 100}% </span>
                              <Trans>to</Trans>{' '}
                              <span className="item-pools__profit-to text-yellow">
                                {' '}
                                {((Number(rates[i]) / 100) * Number(refBonusStages[refBonusStages.length - 1][1])) /
                                  100}
                                %
                              </span>
                            </div>
                            <div className="item-pools__info">
                              <div className="item-pools__info-row">
                                <div className="item-pools__info-column">
                                  <img
                                    className="item-pools__info-icon"
                                    src={require('./img/svg/calendar.svg').default}
                                    alt="calendar"
                                  />
                                  <div className="item-pools__info-text">
                                    <Trans i18nKey="age">Age:</Trans>
                                  </div>
                                </div>
                                <div className="item-pools__info-column">
                                  <div className="item-pools__info-value">
                                    {convertSecondsToDuration(periods[i]).split(' ')[0]}{' '}
                                    {t(convertSecondsToDuration(periods[i]).split(' ')[1], {
                                      count: Number(convertSecondsToDuration(periods[i]).split(' ')[0]),
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="item-pools__info-row">
                                <div className="item-pools__info-column">
                                  <img
                                    className="item-pools__info-icon"
                                    src={require('./img/svg/thumbs.svg').default}
                                    alt="thumbs"
                                  />
                                  <div className="item-pools__info-text">TVL:</div>
                                </div>
                                <div className="item-pools__info-column">
                                  <div className="item-pools__info-value item-pools__info-value--tvl">
                                    {ethers.formatEther(TVLs[i])} AVO
                                  </div>
                                </div>
                              </div>
                              {/* <div className="item-pools__info-row">
                                <div className="item-pools__info-column">
                                  <div className="item-pools__info-value">~$0</div>
                                </div>
                              </div> */}
                              <div className="item-pools__info-row">
                                <div className="item-pools__info-column">
                                  <div className="item-pools__info-text">
                                    <Trans i18nKey="minStake">Min stake:</Trans>
                                  </div>
                                </div>
                                <div className="item-pools__info-column">
                                  <div className="item-pools__info-value item-pools__info-value--tvl">
                                    {ethers.formatEther(minStakeAmount)} AVO
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                        ))}
                      </div>
                    ) : (
                      <div style={{ textAlign: 'center', padding: 'calc(245px / 2)' }}>
                        <PropagateLoader color="#e3fb4c" style={{ margin: 'calc(2.6875rem / 2) auto' }} />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="staking__pools-partnership">
                  <div className="pools-partnership">
                    <h2 className="pools-partnership__title">Staking pools</h2>

                    {partnershipPools.length > 0 ? (
                      <ul className="pools-partnership__list">
                        {partnershipPools.map((pool, i) => (
                          <li key={i} className="pools-partnership__item item-pools-partnership">
                            <div className="item-pools-partnership__label">Partnership</div>
                            <div className="item-pools-partnership__content">
                              <button
                                type="button"
                                className={`item-pools-partnership__title ${activeSpoller === i + 1 ? '_active' : ''}`}
                                onClick={() => setActiveSpoller(activeSpoller === i + 1 ? 0 : i + 1)}
                              >
                                <div className="item-pools-partnership__image">
                                  <img
                                    src={require('./img/svg/image.svg').default}
                                    alt=""
                                    className="item-pools-partnership__icon"
                                  />
                                </div>
                                <div className="item-pools-partnership__header">
                                  <div className="item-pools-partnership__column">
                                    <div className="item-pools-partnership__name">{pool.rewardTokenSymbol}</div>
                                    <div className="item-pools-partnership__tvl text-yellow">
                                      TVL {ethers.formatEther(pool.totalDepositedAmount)} {pool.depositedTokenSymbol}
                                    </div>
                                  </div>
                                  <div className="item-pools-partnership__column">
                                    <div className="item-pools-partnership__apr">
                                      APR: <span className="change-up">{pool.accRewardsPerShare}%</span>
                                    </div>
                                    <div className="item-pools-partnership__age">
                                      <Trans i18nKey="age">AGE:</Trans> {convertSecondsToDuration(pool.harvestPeriod)}
                                    </div>
                                  </div>
                                </div>
                                <div className="item-pools-partnership__arrow">
                                  <svg className="item-pools-partnership__arrow-icon">
                                    <use xlinkHref={`${sprite}#arrow`}></use>
                                  </svg>
                                </div>
                              </button>
                              <div
                                className={`item-pools-partnership__body ${activeSpoller === i + 1 ? 'active' : ''}`}
                              >
                                <div className="item-pools-partnership__inner">
                                  <div className="item-pools-partnership__descr">
                                    <div className="item-pools-partnership__amount-container">
                                      <div className="item-pools-partnership__amount">
                                        <span className="text-yellow">Your stake:</span>{' '}
                                        {partnershipUser.length > 0
                                          ? ethers.formatEther(partnershipUser[i].depositedAmount)
                                          : '-'}{' '}
                                        {pool.depositedTokenSymbol}
                                      </div>
                                      <div className="item-pools-partnership__amount">
                                        <span className="text-yellow">Your earn: </span>
                                        {partnershipUser.length > 0
                                          ? ethers.formatEther(partnershipUser[i].rewardDebt)
                                          : '-'}{' '}
                                        {pool.rewardTokenSymbol}
                                      </div>
                                    </div>
                                    <div className="item-pools-partnership__timer timer">
                                      <h3 className="timer__title">
                                        <Trans i18nKey="timeLeft">Time left</Trans>:
                                      </h3>
                                      <div className="timer__items">
                                        <div className="timer__block">
                                          <div className="timer__time">
                                            {calculateTimeLeft(
                                              pool.lastRewardBlockTimestamp,
                                              Number(pool.harvestPeriod)
                                            )
                                              .split(' ')[0]
                                              .slice(0, -1)}
                                          </div>
                                          <div className="timer__label">D</div>
                                        </div>
                                        <div className="timer__block">
                                          <div className="timer__time">
                                            {calculateTimeLeft(
                                              pool.lastRewardBlockTimestamp,
                                              Number(pool.harvestPeriod)
                                            )
                                              .split(' ')[1]
                                              .slice(0, -1)}
                                          </div>
                                          <div className="timer__label">H</div>
                                        </div>
                                        <div className="timer__block">
                                          <div className="timer__time">
                                            {calculateTimeLeft(
                                              pool.lastRewardBlockTimestamp,
                                              Number(pool.harvestPeriod)
                                            )
                                              .split(' ')[2]
                                              .slice(0, -1)}
                                          </div>
                                          <div className="timer__label">M</div>
                                        </div>
                                        {/* <div className="timer__block">
                                        <div className="timer__time">43</div>
                                        <div className="timer__label">S</div>
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="item-pools-partnership__transactions">
                                    <div className="transactions">
                                      <div className="transactions__tabs tabs">
                                        <nav className="transactions__navigation tabs__navigation">
                                          <button
                                            type="button"
                                            className={`transactions__title tabs__title ${
                                              activeSpollerTab === 0 ? '_tab-active' : ''
                                            }`}
                                            onClick={() => setActiveSpollerTab(0)}
                                          >
                                            Stake
                                          </button>
                                          <button
                                            type="button"
                                            className={`transactions__title tabs__title ${
                                              activeSpollerTab === 1 ? '_tab-active' : ''
                                            }`}
                                            onClick={() => setActiveSpollerTab(1)}
                                          >
                                            withdraw
                                          </button>
                                        </nav>
                                        {loading ? (
                                          <div
                                            style={{
                                              display: 'flex',
                                              flex: 1,
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              margin: '50px 0',
                                            }}
                                          >
                                            <RingLoader color="#e3fb4c" />
                                          </div>
                                        ) : (
                                          <div className="transactions__content tabs__content">
                                            <div
                                              className="transactions__body tabs__body"
                                              style={{ display: activeSpollerTab === 0 ? 'block' : 'none' }}
                                            >
                                              <form
                                                onSubmit={(e) => {
                                                  e.preventDefault()
                                                  partnerStakeHandler(i, e.target[0].value)
                                                }}
                                                className="transactions__form"
                                              >
                                                <label className="transactions__label">
                                                  <Trans i18nKey="avoAmount">AVO Amount</Trans>
                                                </label>
                                                <div className="transactions__control">
                                                  <input
                                                    type="number"
                                                    className="transactions__input"
                                                    placeholder="500000"
                                                  />
                                                  {/* <button className="transactions__btn-max">MAX</button> */}
                                                </div>
                                                <button className="transactions__button button">Approve</button>
                                              </form>
                                            </div>
                                            <div
                                              className="transactions__body tabs__body"
                                              style={{ display: activeSpollerTab === 1 ? 'block' : 'none' }}
                                            >
                                              <form
                                                onSubmit={(e) => {
                                                  e.preventDefault()
                                                  partnerUnstakeHandler(i, e.target[0].value)
                                                }}
                                                className="transactions__form"
                                              >
                                                <label className="transactions__label">
                                                  <Trans i18nKey="avoAmount">AVO Amount</Trans>
                                                </label>
                                                <div className="transactions__control">
                                                  <input
                                                    type="number"
                                                    className="transactions__input"
                                                    placeholder="500000"
                                                  />
                                                  {/* <button className="transactions__btn-max">MAX</button> */}
                                                </div>
                                                <button className="transactions__button button">Withdraw</button>
                                              </form>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div
                        style={{
                          textAlign: 'center',
                          padding: '20px',
                          backdropFilter: 'blur(24px)',
                          background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)',
                          borderRadius: '10px',
                        }}
                      >
                        <Trans i18nKey="noPartnership">There are no partnership pools at the moment</Trans>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {account && (
                <div className="staking__my-staking">
                  <div className="my-staking">
                    <h2 className="my-staking__title text-yellow">
                      <Trans i18nKey="myStaking">MY STAKING</Trans>
                    </h2>
                    <div className="my-staking__table">
                      <div className="my-staking__head">
                        <div className="my-staking__items my-staking__items--head">
                          <div className="my-staking__item">
                            <Trans i18nKey="stakingAmount">Staking amount</Trans>
                          </div>
                          <div className="my-staking__item">
                            <Trans i18nKey="timeLeft">Time left</Trans>
                          </div>
                          <div className="my-staking__item">
                            <Trans i18nKey="yourAPR">Your APR</Trans>
                          </div>
                          <div className="my-staking__item">Expected Revenue</div>
                          <div className="my-staking__item">
                            <Trans i18nKey="action">Action</Trans>
                          </div>
                        </div>
                      </div>
                      <div className="my-staking__body">
                        {myStakes.length === 0 ? (
                          <div
                            style={{
                              textAlign: 'center',
                              padding: '20px',
                              backdropFilter: 'blur(24px)',
                              background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)',
                              borderRadius: '10px',
                            }}
                          >
                            You have no staking positions yet
                          </div>
                        ) : (
                          myStakes.map((stake, index) => (
                            <div
                              key={stake.stakedTime}
                              className="my-staking__items my-staking__items--data card card--my-staking"
                            >
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">
                                  <Trans i18nKey="stakingAmount">Staking amount</Trans>
                                </div>
                                <div className="my-staking__amount">
                                  <img
                                    src={require('./img/logo-icon.svg').default}
                                    alt=""
                                    className="my-staking__amount-icon"
                                  />
                                  <div className="my-staking__revenue">
                                    <div className="my-staking__revenue-avo">
                                      {new Intl.NumberFormat('fr-FR').format(ethers.formatEther(stake.amount))} AVO
                                    </div>
                                    <div className="my-staking__revenue-convert text-yellow">~ $ 0</div>
                                  </div>
                                </div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">
                                  <Trans i18nKey="timeLeft">Time left</Trans>
                                </div>
                                <div className="my-staking__item-time">
                                  {calculateTimeLeft(Number(stake.stakedTime), Number(stake.period))}
                                </div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">
                                  <Trans i18nKey="yourAPR">Your APR</Trans>
                                </div>
                                <div className="my-staking__item-apr text-yellow">
                                  {(
                                    (Number(rates[periods.findIndex((d) => d === Number(stake.period))]) / 100) *
                                    (Number(
                                      refBonusStages.findLast((stage) => totalReferrals > Number(stage.numReferrals))
                                        ?.rate
                                    ) / 100 || 1)
                                  ).toFixed(1)}
                                  %
                                </div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">Expected Revenue</div>
                                <div className="my-staking__revenue">
                                  <div className="my-staking__revenue-avo">
                                    {new Intl.NumberFormat('fr-FR').format(ethers.formatEther(stake.reward))} AVO
                                  </div>
                                  <div className="my-staking__revenue-convert text-yellow">~ $ 0</div>
                                </div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__action">
                                  <button
                                    onClick={() => {
                                      setModalUnstakeShow(true)
                                      setUnstakeIndex(index)
                                    }}
                                    className="my-staking__button button"
                                  >
                                    Unstake
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="staking__footnotes">
              <div className="footnotes">
                <div className="footnotes__content">
                  <p className="footnotes__text">
                    <Trans i18nKey="aprExplain">
                      <span>APR</span> — Annual Percentage Rate is a standardized metric used to express the cost of the
                      potential return on staking a certain amount of cryptocurrency over a year.
                    </Trans>
                  </p>
                  <p className="footnotes__text">
                    <Trans i18nKey="tvlExplain">
                      <span>TVL</span> — Total value locked is a metric used in the cryptocurrency sector to determine
                      the total U.S. dollar value of digital assets staked via decentralized finance (DeFi) platforms or
                      decentralized applications (dApps). The higher the TVL of a project, the more secure and valuable
                      it is perceived to be.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="footer">
          <div className="footer__container ">
            <div className="footer__column">
              <a href="/" className="footer__logo logo logo">
                <img
                  className="logo__icon logo__icon--footer"
                  src={require('./img/logo-icon.svg').default}
                  alt="logo"
                />
                <div className="logo__name logo__name--footer">
                  <img
                    className="logo__name-icon logo__name-text--footer"
                    src={require('./img/logo-name.svg').default}
                    alt="logo"
                  />
                  <span className="logo__name-text logo__name-text--footer">finance</span>
                </div>
              </a>
              <p className="footer__text">
                <Trans i18nKey="future">The future that is already accessible now</Trans>
              </p>
            </div>
            <div className="footer__column">
              <a href="https://avo.center/en" target="_blank" rel="noreferrer" className="footer__about">
                About AVOUNIVERSE
              </a>
            </div>
          </div>
        </footer>
      </div>
      <div className={`modal ${modalShow || modalUnstakeShow || modalDisconnectShow ? 'is-open' : ''}`}>
        <div className={`modal__container ${modalShow ? 'modal-open' : ''}`} role="dialog">
          <button className="modal__close js-modal-close" onClick={() => setModalShow(false)}>
            <svg className="modal__close-icon">
              <use xlinkHref={`${sprite}#close`}></use>
            </svg>
          </button>
          <div className="modal__body">
            <div className="modal__image">
              <picture>
                <source srcSet={safeWebp} type="image/webp" />
                <img src={require('./img/safe.png')} className="image" alt="" />
              </picture>
            </div>

            <h2 className="modal__title">STAKING</h2>
            <p className="modal__text">
              <Trans i18nKey="selectedPool1">You have selected staking pool for</Trans> <br />
              <span className="text-yellow">
                {convertSecondsToDuration(Number(periods[stakingDurationIndex])).split(' ')[0]}{' '}
                {t(convertSecondsToDuration(Number(periods[stakingDurationIndex])).split(' ')[1], {
                  count: Number(convertSecondsToDuration(Number(periods[stakingDurationIndex])).split(' ')[0]),
                })}
              </span>{' '}
              ,<Trans i18nKey="selectedPool2"> please specify the number of AVO you wish to stake:</Trans>
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                account ? stakeHandler(e.target.stakeValue.value) : connectWallet()
              }}
              className="modal__form"
            >
              <label className="modal__form-label">
                <Trans i18nKey="avoAmount">AVO Amount</Trans>
              </label>
              <div className="modal__form-control">
                <input
                  disabled={loading || !account}
                  type="number"
                  id="stakeValue"
                  className="modal__form-input"
                  placeholder="500000"
                  step=".01"
                  min={ethers.formatEther(minStakeAmount)}
                  required
                />
                {/* <button className="modal__form-btn-max">MAX</button> */}
              </div>
              <div className="modal__form-app">
                Your{' '}
                <span className="text-yellow">
                  APR{' '}
                  {(
                    (Number(rates[stakingDurationIndex]) / 100) *
                    (Number(refBonusStages.findLast((stage) => totalReferrals > Number(stage.numReferrals))?.rate) /
                      100 || 1)
                  ).toFixed(1)}
                  %
                </span>
              </div>
              {!loading ? (
                <button className="modal__form-button button js-modal-close">
                  {t(account ? 'STAKE' : 'CONNECT WALLET')}
                </button>
              ) : (
                <PropagateLoader color="#e3fb4c" style={{ margin: 'calc(2.6875rem / 2) auto' }} />
              )}
            </form>
          </div>
        </div>

        <div className={`modal__container ${modalUnstakeShow ? 'modal-open' : ''}`} role="dialog">
          <button className="modal__close js-modal-close" onClick={() => setModalUnstakeShow(false)}>
            <svg className="modal__close-icon">
              <use xlinkHref={`${sprite}#close`}></use>
            </svg>
          </button>
          <div className="modal__body">
            <div className="modal__image">
              <picture>
                <source srcSet={safeWebp} type="image/webp" />
                <img src={require('./img/safe.png')} className="image" alt="" />
              </picture>
            </div>

            <h2 className="modal__title">UNSTAKING</h2>
            <p className="modal__text">
              <Trans i18nKey="unstakingAmount">You are unstaking tokens for amount</Trans>
              <br />
              <span className="text-yellow">
                {unstakeIndex !== null ? ethers.formatEther(myStakes[unstakeIndex]?.amount) : 0}
                {' + '}
                {unstakeIndex !== null ? ethers.formatEther(myStakes[unstakeIndex]?.reward) : 0} AVO
              </span>
              ,
            </p>
            <div className="modal__unstake">
              <p className="modal__unstake-text">Unstake fee 5%</p>
              <p className="modal__unstake-text">
                Your{' '}
                <span className="text-yellow">
                  APR{' '}
                  {(
                    (Number(rates[stakingDurationIndex]) / 100) *
                    (Number(refBonusStages.findLast((stage) => totalReferrals > Number(stage.numReferrals))?.rate) /
                      100 || 1)
                  ).toFixed(1)}
                  %
                </span>
              </p>
            </div>
            {!loading ? (
              <button onClick={unstakeHandler} className="modal__form-button button js-modal-close">
                UNSTAKE
              </button>
            ) : (
              <div style={{ textAlign: 'center', minHeight: '2.6875rem', alignContent: 'center' }}>
                <PropagateLoader color="#e3fb4c" />
              </div>
            )}
          </div>
        </div>

        <div className={`modal__container ${modalDisconnectShow ? 'modal-open' : ''}`} role="dialog">
          <button className="modal__close js-modal-close" onClick={() => setModalDisconnectShow(false)}>
            <svg className="modal__close-icon">
              <use xlinkHref={`${sprite}#close`}></use>
            </svg>
          </button>
          <div className="modal__body">
            {/* <div className="modal__image">
              <picture>
                <source srcSet={safeWebp} type="image/webp" />
                <img src={require('./img/safe.png')} className="image" alt="" />
              </picture>
            </div> */}

            <h2 className="modal__title">DISCONNECT</h2>
            <p className="modal__text">
              If you want to change your account, please use MetaMask modal window
              <br />
            </p>

            <button
              onClick={() => {
                disconnectWallet() && setModalDisconnectShow(false)
              }}
              className="modal__form-button button js-modal-close"
            >
              DISCONNECT
            </button>
          </div>
        </div>

        <div
          className="modal__overlay"
          onClick={() => {
            setModalUnstakeShow(false)
            setModalShow(false)
            setModalDisconnectShow(false)
          }}
        ></div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertShow}
        autoHideDuration={3000}
        onClose={() => setAlertShow(false)}
      >
        <div
          onClose={() => setAlertShow(false)}
          style={{
            width: '100%',
            height: '50px',
            backgroundColor: 'white',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
            padding: '0 10px',
          }}
        >
          {t(alertText)}
        </div>
      </Snackbar>
    </div>
  )
}

export default App
