import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import i18next from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'

import global_ru from './locales/ru/global.json'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'ru'],
    interpolation: { escapeValue: false },
    debug: true,
    resources: {
      ru: {
        translation: global_ru,
      },
    },
  })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
)
