import { ethers } from 'ethers'
import {
  contractAddress,
  contractABI,
  chainParams,
  stakingTokenParams,
  rewardTokenParams,
  contractPartnershipAddress,
  contractPartnershipABI,
} from './contractInfo'

export const checkMetamaskInstallation = async (
  setProvider,
  setSigner,
  setContract,
  setPartnershipContract,
  setAccount
) => {
  if (window.ethereum === undefined) {
    alert('Metamask wallet is not installed')
    return
  } else {
    initiateWalletConnection(setProvider, setSigner, setContract, setPartnershipContract, setAccount)
  }
}

export const initiateWalletConnection = async (
  setProvider,
  setSigner,
  setContract,
  setPartnershipContract,
  setAccount
) => {
  try {
    let switched = true
    if (window.ethereum?.chainId !== chainParams.chainId) {
      switched = await switchNetwork()
    }

    const _provider = new ethers.BrowserProvider(window.ethereum)
    const _accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    const _signer = await _provider.getSigner()
    setProvider(_provider)
    setSigner(_signer)
    setAccount(_accounts[0])
    localStorage.setItem('metamaskConnected', 'true')
    if (switched) {
      const _partnershipContract = new ethers.Contract(contractPartnershipAddress, contractPartnershipABI, _signer)
      setPartnershipContract(_partnershipContract)
      const _contract = new ethers.Contract(contractAddress, contractABI, _signer)
      setContract(_contract)
      // try {
      //   // Request to add the custom token

      //   // const rewardTokenAdded = await window.ethereum.request({
      //   //   method: 'wallet_watchAsset',
      //   //   params: rewardTokenParams,
      //   // })

      //   // if (rewardTokenAdded) {
      //   //   console.log('Reward Token added successfully.')
      //   // } else {
      //   //   console.log('Reward Token addition was rejected.')
      //   // }

      //   const stakingTokenAdded = await window.ethereum.request({
      //     method: 'wallet_watchAsset',
      //     params: stakingTokenParams,
      //   })

      //   if (stakingTokenAdded) {
      //     console.log('Staking Token added successfully.')
      //   } else {
      //     console.log('Staking Token addition was rejected.')
      //   }
      // } catch (error) {
      //   console.error('Error adding chain or token:', error)
      // }
    }
  } catch (error) {
    console.log(error)
  }
}

export const addChainId = async () => {
  try {
    const provider = new ethers.BrowserProvider(window.ethereum)
    await provider.send('wallet_addEthereumChain', [chainParams])
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const switchNetwork = async () => {
  try {
    const provider = new ethers.BrowserProvider(window.ethereum)
    await provider.send('wallet_switchEthereumChain', [{ chainId: chainParams.chainId }])
    return true
  } catch (error) {
    if (error.error.code === 4902) {
      let added = await addChainId()
      return added
    } else {
      console.log(error.error.message)
      return false
    }
  }
}
